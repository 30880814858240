<script setup>
//import store user
import { useUser } from "../stores/user";

import $ from 'jquery';

//import services api
import Api from '../services/api'

// Select 2
import Select2 from 'vue3-select2-component';
// import Select2 from 'v-select2-component';
import vSelect from 'vue-select';

import { useLoading } from 'vue-loading-overlay'
import 'vue-select/dist/vue-select.css';
import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net-bs5';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Swal from 'sweetalert2';

import 'vue-select/dist/vue-select.css';
import { GaugeChart } from 'echarts/charts'
import {
	GridComponent,
	LegendComponent,
	TitleComponent,
	TooltipComponent,
} from 'echarts/components';
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { ref, onBeforeMount, onMounted, onUnmounted, onBeforeUnmount } from 'vue';
import VChart from 'vue-echarts';
//import useRouter from vue router
import { useRouter } from "vue-router";

DataTable.use(DataTablesCore);

const userStore = useUser();
//inisialisasi vue router on Composition API
const router = useRouter();

const interval = ref(null)
const myOptions = [{ id: 1, text: 'test1' }, { id: 2, text: 'test 2' }]

const optUnit = ref([]);
const optKategori = ref([]);
const myValue = ref(null);

const chartOptions = ref([]);
const alat = ref([]);
const classes = ref();
const users = JSON.parse(localStorage.user);
const show = ref(true);
const playIt = ref(true);
const buttonAudio = ref(false);
const sync = ref(true);

let tableGroupInstance;
const tableGroup = ref();
const tableGroupData = ref([]);

let modalInstance = null;
let modalKalibrasiInstance = null;
const configObject = { keyboard: false, backdrop: 'static' }

const dataTableOptions = ref({
	order: [],
	scrollX: true,
	initComplete: function () {
		// 
	},
	drawCallback: function (settings) {
		const elementsToRemoveShown = document.querySelectorAll('tr.shown > td.details-control');
		elementsToRemoveShown.forEach(element => {
			const parentTR = element.closest('tr');
			if (parentTR) {
				parentTR.classList.remove('shown');
			}
		});
	},
});

const clickEventHandler = async (event) => {
	const targetSubGrid = event.target.closest('td.details-control');
	if (targetSubGrid) {

		console.log('subgrid clicked');

		const tr = event.target.closest('tr');
		const row = tableGroupInstance.row(tr)

		if (document.getElementById(`tr-${row.data().key_id}`)) {
			tr.classList.remove('shown')
			$(`#tr-${row.data().key_id}`).remove()
		} else {
			expandSubGrid(tr, row.data())
			tr.classList.add('shown')
		}
		setTimeout(() => {
			tableGroupInstance.columns.adjust()
		}, 0);
	}

	const targetNavLink = event.target.closest('.nav-link');
	if (targetNavLink) {
		const id = targetNavLink.getAttribute('data-id-header');

		$(`table#datatable-detail-${id} > thead > tr`).css({ visibility: 'collapse' });
		setTimeout(() => {
			$(`#datatable-detail-${id}`).DataTable().columns.adjust()
			$(`table#datatable-detail-${id} > thead > tr`).css({ visibility: 'collapse' });
			//tableGroupInstance.columns.adjust()
		}, 0);
	}
}

const expandSubGrid = async (tr, data) => {
	let subGridData = []

	const loader = loading.show({});
	try {
		const response = await Api.get('api/home/subdatatable', {
			params: { id_unit: data.key_id_unit_kerja, waktu: data.waktu },
			headers: {
				"Accept": "application/json",
				"Content-Type": "application/json",
				'Authorization': 'Bearer ' + localStorage.token
			},
		});
		subGridData = response.data.data

	} catch (error) {
		console.error(error);
	} finally {
		loader.hide()
	}

	tr.insertAdjacentHTML('afterend', `
        <tr id="tr-${data.key_id}">
            <td></td>
            <td colspan='5' style="max-width: 0px !important;">
                <nav>
                    <div class="nav nav-tabs" id="nav-tab-${data.key_id}" role="tablist">
                        <button data-id-header="${data.key_id}" class="nav-link active" id="nav-lokasi-${data.key_id}-tab" data-bs-toggle="tab" data-bs-target="#nav-lokasi-${data.key_id}"
                            type="button" role="tab" aria-controls="nav-lokasi-${data.key_id}" aria-selected="true">History</button>                         
                    </div>
                </nav>
                <div class="tab-content" id="nav-tabContent-${data.key_id}">
                    <div class="tab-pane fade show active" id="nav-lokasi-${data.key_id}" role="tabpanel" aria-labelledby="nav-lokasi-${data.key_id}-tab" tabindex="0">
                        <div class="mt-3">
                            <table style="width: 100%; height: 100%;" id='datatable-detail-${data.key_id}' class='table table-bordered table-striped datatable-details'>
                            </table>
                        </div>
                    </div>                    
                </div>
            </td>
        </tr>
    `);

	await $(`#datatable-detail-${data.key_id}`).dataTable({
		order: [],
		scrollX: true,
		data: subGridData,
		columns: [
			{ title: "ID", data: 'key_id', width: "20px", className: 'text-start', visible: false },
			{ title: "TimeLine", data: 'timeline', className: 'text-start' },
			{
				title: "Windspeed", data: 'windspeed',
				render: function (datax, type, row) {
					let $nilai = ``;
					if (row.status == '1') {
						$nilai = `<div class="p-3 mb-2 bg-success text-white" style="margin-top:-8px;margin-left:-8px;margin-right:-8px;margin-bottom:-8px;padding-left:2px;"><span class"bg-danger">${row.windspeed}</span></div>`;
					} else if (row.status == '2') {
						$nilai = `<div class="p-3 mb-2 bg-danger text-white" style="margin-top:-8px;margin-left:-8px;margin-right:-8px;margin-bottom:-8px;padding-left:2px;"><span class"bg-danger">${row.windspeed}</span></div>`;
					} else {
						$nilai = `<span>${row.windspeed}</span>`;

					}
					return $nilai;
				}
			},
			{ title: "Direction", data: 'nama_en', className: 'text-start' },

			// {
			//     title: 'Aksi',
			//     orderable: false,
			//     data: null,
			//     className: 'text-start',
			//     render: function (datax, type, row) {
			//         console.log(row);
			//         const disabled = false ? "disabled" : ""
			//         const div = `<div class="d-flex">
			//                     <button class="btn btn-sm btn-outline-primary btn-edit-lokasi mr-2" ${disabled} title="Ubah Lokasi" data-id=${row.key_id} data-id-header=${data.key_id}><i class="operation-icon fas fa-pen"></i></button>
			//                     <button class="btn btn-sm btn-outline-danger btn-remove-lokasi" ${disabled} title="Hapus Lokasi" data-id=${row.key_id}><i class="operation-icon fas fa-trash-alt"></i></button>
			//                 </div>`;
			//         return div;
			//     }
			// },
		],
		initComplete: function (settings, json) {
			$(`table#datatable-detail-${data.key_id} > thead > tr`).css({ visibility: 'collapse' });
		},
		drawCallback: function (settings) {
			$(`table#datatable-detail-${data.key_id} > thead > tr`).css({ visibility: 'collapse' });
			setTimeout(() => {
				$(`#datatable-detail-${data.key_id}`).DataTable().columns.adjust()
				$(`table#datatable-detail-${data.key_id} > thead > tr`).css({ visibility: 'collapse' });
				tableGroupInstance.columns.adjust()
			}, 0);

		}
	})
}

const toExactMinute = 15000; //(5000 * 12) * 1; //(60000 - (new Date().getTime() % 60000)) * 1;
const loading = useLoading({});
const pdfdoc = ref('data tidak ditemukan');
const suara = ref([]);
var audio = ref();

use([
	CanvasRenderer,
	GaugeChart,
	TitleComponent,
	TooltipComponent,
	LegendComponent,
	GridComponent,
])


//method logout
function logout() {

	if (!playIt) {
		audio.pause();
		audio.onended = 0;
		playIt.value = true;
	}

	//call action pinia "logout"
	userStore.logout().then(() => {
		//redirect to login
		router.push({ name: "login" });
	});
}

function getDirection(angle) {
	var directions = ['North', 'North-East', 'East', 'South-East', 'South', 'South-West', 'West', 'North-West'];
	var index = Math.round(((angle %= 360) < 0 ? angle + 360 : angle) / 45) % 8;
	return directions[index];
}

async function play(id) {

	var audio = document.getElementById("audio" + id).getAttribute("src");
	var audio2 = document.getElementById("audio2" + id).getAttribute("src");
	const audios = [undefined, audio, audio2];

	for (let i = 0; i < audios.length; i++) {
		const item = new Audio(audios[i]);
		await new Promise((resolve) => {
			if (i === 0) {
				// insert desired number of milliseconds to pause here
				setTimeout(resolve, 250);
			} else {
				item.onended = resolve;
				item.play();
			}
		});
	}
}

function processAudio(item) {
	audio = new Audio(item);

	return new Promise((resolve, reject) => {
		setTimeout(() => {
			audio.play();
			audio.onended = resolve;
		}, Math.random() * 250);
	});
}

async function stopDanger() {
	await new Promise((resolve) => {
		if (!playIt.value) {
			audio.pause();
			audio.onended = 0;
			playIt.value = true;
		}
	});
	console.log('Stop items processed');
}

async function playDanger() {
	const sound = suara.value;
	let result = 0

	playIt.value = false;

	for (let x = 0; x < 500; x++) {
		for (let i = 0; i < sound.length; i++) {
			const item = sound[i][0];
			result = await processAudio(item);
		}
	}
	console.log('All items processed');
	playIt.value = true;
}

function myChangeEvent(val) {
	console.log('1');
	console.log(val);
};

function mySelectEvent({ id, text }) {
	// console.log('2');
	console.log({ id, text })
	console.log(myValue.value)
	sync.value = false;
	prefetch();
};

function OnChangeSelect(id) {
	// console.log(id);
	// this.show = id
	show.value = id
	sync.value = false;
	prefetch()

}

async function modal(id) {
	// $('#myModal').modal('show');
	if (!modalInstance) {
		const modalElement = document.getElementById('myModal');
		modalInstance = new bootstrap.Modal(modalElement, configObject);
	}
	modalInstance.show();

	tableGroupInstance = tableGroup.value.dt;
	await fetchData(id);
}

async function modalkalibrasi(id) {
	// $('#myModal').modal('show');
	console.log(id);
	pdfdoc.value = id;
	if (!modalKalibrasiInstance) {
		const modalElement = document.getElementById('myModalKalibrasi');
		modalKalibrasiInstance = new bootstrap.Modal(modalElement, configObject);
	}
	modalKalibrasiInstance.show();

	tableGroupInstance = tableGroup.value.dt;
	// await fetchData(id);
}

const fetchData = async (id) => {
	console.log(id);
	const loader = loading.show({});
	try {
		const { data } = await Api.get('api/home/subdatatable', {
			headers: {
				"Accept": "application/json",
				"Content-Type": "application/json",
				'Authorization': 'Bearer ' + localStorage.token
			}, params: { id: id },
		});
		tableGroupData.value = data.data;
	} catch (error) {
		console.error('Error fetching data:', error);
	} finally {
		loader.hide()
		setTimeout(() => {
			tableGroupInstance.columns.adjust().draw()
		}, 0);
	}
};

const columns = [
	// {
	//   className: 'details-control text-center',
	//   data: null,
	//   defaultContent: '',
	//   width: '20px',
	//   title: "#",
	//   orderable: false
	// }, 
	{ title: "ID", data: 'key_id', width: "20px", className: 'text-center', visible: false },
	{ title: "Hours", width: "50px", data: 'waktu', className: 'text-center', },
	{
		title: "Wind Speed (Knot)", data: 'windspeed', width: "20px",
		"createdCell": function (td, cellData, rowData, row, col) {
			if (rowData.status == '1') {
				$(td).addClass('bg-success');
			} else if (rowData.status == '2') {
				$(td).addClass('bg-danger');
			}
		}
	},
	{ title: "Wind Speed (Km)", data: 'windspeed_km', width: "20px", },
	// { title: "Wind Direction", data: 'derajat', width: "20px", },
	{ title: "Direction", data: 'nama_en', width: "20px", },
];

async function prefetch() {

	// console.log(users.id);
	buttonAudio.value = false;

	if (localStorage.token && sync) {
		try {
			// item = await Api.get('api/windspeed');
			await Api.get('api/home/chartopt',
				{
					headers: {
						"Accept": "application/json",
						"Content-Type": "application/json",
						'Authorization': 'Bearer ' + localStorage.token
					}, params: { id: users.id, group: myValue.value, knot: show.value, sync: sync.value }
				}).then((response) => {
					chartOptions.value = response.data.series;
					alat.value = response.data.alat;
					suara.value = response.data.sounds;
					classes.value = response.data.class;
					stopDanger();
				}).catch((error) => {
					console.log(error)
				}).finally(buttonAudio.value = true);
			// console.log('Ini  -----> ', items.value)
		} catch (error) {
			// do something
		}
	}
}

async function preOption() {
	try {
		await Api.get('api/option',
			{
				headers: {
					"Accept": "application/json",
					"Content-Type": "application/json",
					'Authorization': 'Bearer ' + localStorage.token
				}, params: { id: users.id }
			}).then((response) => {
				// console.log('response.data : ', response.data.data)
				optUnit.value = response.data.data['group']
				myValue.value = '0';
				//console.log('Ini  -----> ', optUnit.value)
				console.log('jalankan prefetch')
				prefetch();
			}).catch((error) => {
				console.log(error)
			}).finally(
			// prefetch()
		);
	} catch (error) {
		// do something
		console.log(error)
	}
}

onBeforeMount(async () => {
	document.removeEventListener('click', clickEventHandler);
	preOption();
	// prefetch();

	document.removeEventListener('click', clickEventHandler);
})

onMounted(async () => {
	buttonAudio.value = false;
	document.removeEventListener('click', clickEventHandler);
	document.addEventListener('click', clickEventHandler);
	// preOption(); 
	interval.value = setInterval(() => {
		console.log('Pre Fetch.');
		sync.value = true;
		prefetch()
		buttonAudio.value = true;
	}, toExactMinute);

})

onBeforeUnmount(() => {  // destroyed  in Vue 2
	console.log('onBeforeUnmount');
	if (!playIt.value) {
		audio.pause();
		audio.onended = 0;
		playIt.value = true;
	}
})

onUnmounted(() => {  // destroyed  in Vue 2
	console.log('Destroy');

	document.removeEventListener('click', clickEventHandler);
	clearInterval(interval.value)
	document.removeEventListener('click', clickEventHandler);
	buttonAudio.value = false;
	// Hentikan Sound --- 
	stopDanger();
})

</script>
<template>
	<content style="margin-top: -15px; padding: 5px; background-color:'#fff'">
		<div class="row">
			<div class="col-md-12">
				<div class="form-group">
					<label>Group Unit</label>
					<Select2 v-model="myValue" :options="optUnit" style="max-width: 98.5% !important;"
						:settings="{ settingOption: value, settingOption: value }" @change="myChangeEvent($event)"
						@select="mySelectEvent($event)">
					</Select2>
				</div>
			</div>
		</div>

		<div class="card">
			<div class="card-body">
				<div class="d-flex justify-content-between">
					<div class="col-2">
						<div class="form-check form-check-inline">
							<input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1"
								value="option1" v-on:change="OnChangeSelect(true)" checked>
							<label class="form-check-label" for="inlineRadio1">Knot</label>
						</div>
						<div class="form-check form-check-inline">
							<input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2"
								value="option2" v-on:change="OnChangeSelect(false)">
							<label class="form-check-label" for="inlineRadio2">Km/h</label>
						</div>
					</div>
					<div class="col-sm">
						<div class="form-check-inline"><span style="font-size: 9px;"><strong>1knots=
									1.85kph</strong></span></div>
					</div>
					<div v-if="buttonAudio">
						<div class="row align-items-end">
							<div class="col-10">
								<span class="text-danger text-end"><strong>Alert Voice</strong></span>
							</div>
							<div class="col-2" style="margin-left:-30%;">
								<div v-if="playIt" class="col-sm text text-end">
									<a @click.prevent="playDanger()"><i
											class="fas fa-volume-up faa faa-flash text-danger"></i></a>
								</div>
								<div v-if="!playIt" class="col-sm text text-end">
									<a @click.prevent="stopDanger()"><i
											class="fas fa-volume-mute text-secondary"></i></a>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>


		<div class="row">
			<section :class="classes" v-for="(item, index) in chartOptions">
				<div class="card">
					<div class="card-header" style="font-size: 12px;"> <strong>{{ alat[index].nama_alat }}</strong>
					</div>
					<div :class="['card-body']" auto-resize>
						<div id="main"
							style="user-select: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); position: relative;">
							<VChart class="chart" :option="item"
								style="user-select: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); position: relative;"
								autoresize />
						</div>

					</div>
					<hr style="visibility: hidden;" />

					<!-- Icon -->
					<div :class="['card-body', alat[index].color]">
						<div class="d-flex flex-row align-items-center justify-content-center">
							<div class="flex-shrink-1 pe-4">
								<img src="../assets/compass_north.svg"
									:style="{ width: 32 + 'px', transform: 'rotate(' + alat[index].nilai_winddirection + 'deg)' }">
							</div>
							<div class="d-flex flex-column border-start border-start-1 border-light ps-2" style="font-size: 20px;">
								<div class="w-100 d-flex align-items-center mb-2">
									<span style="font-size: 14px">&nbsp;&nbsp;{{ alat[index].nama_en }}</span>
								</div>
								<div class="flex-shrink-1 d-flex align-items-center">
									<span style="font-size: 14px">&nbsp;&nbsp;{{ alat[index].nilai_winddirection }}<sup
										style="font-size: 14px">&deg;</sup></span>
								</div>
							</div>
						</div>
					</div>

					<!-- Audio -->
					<div class="card-footer">
						<div class="d-flex flex-row">
							<div class="flex-fill d-flex flex-row">
								<a @click.prevent="play(alat[index].keyid)" :class="['btn', alat[index].color, 'me-2']">
									<i :class="['fas', 'fa-bullhorn']"></i>
								</a>
								<audio :id="['audio' + alat[index].keyid]" :src=alat[index].voice></audio>
								<audio :id="['audio2' + alat[index].keyid]" :src=alat[index].voice2></audio>
								<div style="font-size: 11px;">
									<i class="far fa-bell faa faa-swing text-danger"
										style="color:white; width: 12px;"></i>&nbsp;<strong>{{ alat[index].kondisi }}
									</strong>
									<br>
									<i class="far fa-clock faa faa-swing text-danger"
										style="color:white; width: 12px;"></i>&nbsp;<strong>{{ alat[index].lastupdate }}
									</strong>
								</div>
							</div>
						</div>
					</div>

					<!-- History Kalibrasi -->
					<div class="card-footer">
						<div class="d-flex flex-row">
							<div class="flex-fill d-flex flex-row">
								<a @click.prevent="modal(alat[index].keyid)" class="btn bg-info me-2"><i
										:class="['fas', 'fa-info-circle']"></i></a>
								<div style="font-size: 11px;">
									<strong>History</strong>
									<br />
									<strong>today</strong>
								</div>
							</div>
							<div class="flex-fill d-flex flex-row">
								<a v-if="(alat[index].tgl_kalibrasi)"
									@click.prevent="modalkalibrasi(alat[index].doc_kalibrasi)"
									:class="['btn', alat[index].color, 'me-2']"><i
										:class="['fas', 'fa-file-pdf']"></i></a>
								<div style="font-size: 11px;">
									<strong>Dok.Kalibrasi </strong>
									<br />
									<strong>{{ alat[index].tgl_kalibrasi }}</strong>
								</div>
							</div>
						</div>
					</div>

				</div>
			</section>
		</div>

		<!-- Modal Disini  -->
		<div class="modal" id="myModal">
			<div class="modal-dialog modal-xl">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">Wind Speed And Direction History</h5>
						<button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<DataTable :columns="columns" :data="tableGroupData" :options="dataTableOptions"
							ref="tableGroup" class="table table-bordered dataTableGroupClass" width="100%"></DataTable>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
					</div>
				</div>
			</div>
		</div>

		<!-- Modal Disini  -->
		<div class="modal" id="myModalKalibrasi">
			<div class="modal-dialog modal-xl">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">Dokumen Kalibrasi</h5>
						<button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<embed :src=pdfdoc width="100%" height="498px" />
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
					</div>
				</div>
			</div>
		</div>
	</content>
</template>

<script>

</script>

<style>
@import 'bootstrap';
@import 'datatables.net-bs5';
</style>

<style scoped>
.select2-selection__rendered {
	display: block;
	padding-left: 8px;
	padding-right: 20px;
	margin: -10px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.chart {
	/* width: 400px;
  min-width: 100%; */
	width: 100%;
	height: 275px;
	margin-bottom: -10vh;
	margin-top: -4vh;
}

.select2-dropdown {
	z-index: 99999 !important;
	/* Ensure this is higher than the modal z-index */
}

.xxx {
	position: relative;
	/* Ensure the parent has positioning */
}

table.datatable-details>thead>tr>th,
table.datatable-details-2>thead>tr>th {
	padding: 8px 30px 8px 8px !important;
	min-height: 41.2px !important;
}

div.dt-scroll-body>table.datatable-details>thead,
div.dt-scroll-body>table.datatable-details-2>thead {
	/* display: none !important; */
	/* height: 1px !important; */
}

div.dt-scroll-body>table.dataTableGroupClass {
	overflow: hidden !important;
}
</style>