<script setup>
import { ref, onBeforeMount, onMounted, onUnmounted } from "vue";

import Select2 from "vue3-select2-component";
// import Select2 from 'v-select2-component';

// Import component
import { useLoading } from "vue-loading-overlay";

//import services api
import Api from "../services/api";

import { GaugeChart } from 'echarts/charts'
import {
	GridComponent,
	LegendComponent,
	TitleComponent,
	TooltipComponent,
} from 'echarts/components';
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import VChart from 'vue-echarts';

use([
	CanvasRenderer,
	GaugeChart,
	TitleComponent,
	TooltipComponent,
	LegendComponent,
	GridComponent,
])

const items = ref([]);
const optionList1 = ref([]);
const myValue = ref(null);
const interval = ref(null);
const $loading = useLoading({});
const myOptions = ref([]);
const datetimeserver = ref();
const show = ref(true);
const sync = ref(true);
const chartOptions = ref([]);
const pdfdoc = ref('data tidak ditemukan');
const suara = ref([]);
var audio = ref();
const alat = ref([]);
const classes = ref();
let modalKalibrasiInstance = null;
const configObject = { keyboard: false, backdrop: 'static' }
let tableGroupInstance;
const tableGroup = ref();
const tableGroupData = ref([]);

function animateValue(id, start, end, duration) {
	const INTERVAL_TIME = 10;
	if (duration < 100) {
		duration = 100;
	} else if (duration > 10000) {
		duration = 10000;
	}

	let obj = document.getElementById(id);
	let decimals = end % 1 != 0 ? (end.toString().split(".")[1] || "").length : 0;

	if (end === start) {
		obj.innerHTML = start.toFixed(decimals);
		return;
	}

	const stepTime = Math.ceil(duration / INTERVAL_TIME);
	let doneLoops = 0;

	let quantityPerLoop = end / stepTime;

	let increment = (end - start) / stepTime;
	let current = start;

	const INTERVAL = setInterval(function () {
		current += quantityPerLoop;
		obj.innerHTML = current.toFixed(decimals);
		if (
			(increment > 0 && current >= end) ||
			(increment < 0 && current <= end)
		) {
			clearInterval(INTERVAL);
			obj.innerHTML = parseFloat(end).toFixed(decimals);
		}
	}, INTERVAL_TIME);
}

async function prefetch() {
	// const loader =  $loading.show({loader : "dots", backgroundcolor : "#fff"});
	try {
		// item = await Api.get('api/windspeed');
		Api.get("api/windspeed", {
			params: { knot: show.value }
		}).then((response) => {
			// items.value = response.data.alat; // <---- assign to the ref's value
			chartOptions.value = response.data.series;
			alat.value = response.data.alat;
			suara.value = response.data.sounds;
			classes.value = response.data.class;
			// loader.hide();
		})
			.catch((error) => {
				console.log(error);
			});
		// console.log('Ini  -----> ', items.value)
	} catch (error) {
		// do something
		console.log(error);
	}
}

async function preOption() {
	try {
		Api.get("api/option")
			.then((response) => {
				console.log("response.data : ", response.data.data);
				myOptions.value = response.data.data["group"];
				console.log("Ini  -----> ", myOptions.value);
			})
			.catch((error) => {
				console.log(error);
			});
	} catch (error) {
		// do something
		console.log(error);
	}
}

function myChangeEvent(val) {
	console.log(val);
}

function mySelectEvent({ id, text }) {
	console.log({ id, text });
}

function getDirection(angle) {
	var directions = [
		"North",
		"North-East",
		"East",
		"South-East",
		"South",
		"South-West",
		"West",
		"North-West",
	];
	var index = Math.round(((angle %= 360) < 0 ? angle + 360 : angle) / 45) % 8;
	return directions[index];
}

async function play(id) {

	var audio = document.getElementById("audio" + id).getAttribute("src");
	var audio2 = document.getElementById("audio2" + id).getAttribute("src");
	const audios = [undefined, audio, audio2];

	for (let i = 0; i < audios.length; i++) {
		const item = new Audio(audios[i]);
		await new Promise((resolve) => {
			if (i === 0) {
				// insert desired number of milliseconds to pause here
				setTimeout(resolve, 250);
			} else {
				item.onended = resolve;
				item.play();
			}
		});
	}
}

function OnChangeSelect(id) {
	// console.log(id);
	// this.show = id
	show.value = id
	sync.value = false;
	prefetch()

}

async function modalkalibrasi(id) {
	// $('#myModal').modal('show');
	console.log(id);
	pdfdoc.value = id;
	if (!modalKalibrasiInstance) {
		const modalElement = document.getElementById('myModalKalibrasi');
		modalKalibrasiInstance = new bootstrap.Modal(modalElement, configObject);
	}
	modalKalibrasiInstance.show();

	tableGroupInstance = tableGroup.value.dt;
	// await fetchData(id);
}

onBeforeMount(async () => {
	// preOption();
	prefetch();
});

onMounted(() => {
	window.Echo.channel('waktu-server')
		.listen('WaktuServer', (e) => {
			datetimeserver.value = e.datetime

			const divX = document.getElementById('navbar-top-target-min');
			if (divX) {
				const fullHeight = window.innerHeight
				const divA = document.getElementById('navbar-top-target-min');
				const navbarHeight = divA.offsetHeight;
				const divB = document.getElementById('footer-bottom-target-min');
				const footerHeight = divB.offsetHeight
				const targetResize = document.getElementById("target-resize")
				targetResize.style.height = `${fullHeight - navbarHeight - footerHeight}px`
			}
		})

	interval.value = setInterval(() => {
		console.log("Pre Fetch.");
		prefetch();
	}, 15000);
});

onUnmounted(() => {
	// destroyed  in Vue 2
	console.log("Destroy");
	clearInterval(interval.value);
});
</script>

<template>
	<div class="wrapper" style="height: 100vh; overflow-y: auto;">
		<div class="content">
			<nav id="navbar-top-target-min" class="navbar navbar-expand navbar-dark navbar-light px-2">
				<ul class="navbar-nav" style="margin-right: 20px">
					<li class="nav-item d-none d-sm-inline-block">
						<img class="imgs custom-size me-3" src="../../public/windspeed.png" alt="Windspeed" />
					</li>
					<li class="nav-item d-none d-sm-inline-block" style="">
						<router-link :to="{ name: 'home' }" class="navbar-brand"><i
								class="fa fa-home"></i></router-link>
					</li>
					<li class="nav-item">
						<div class="nav-link d-block d-sm-none" style="font-size: 14px">
							<span>Waktu Server: {{ datetimeserver }}</span>
						</div>
					</li>
				</ul>

				<ul class="navbar-nav ml-auto">
					<li class="nav-item d-none d-sm-block">
						<div class="nav-link">
							<span>Waktu Server: {{ datetimeserver }}</span>
						</div>
					</li>

					<li class="nav-item">
						<router-link :to="{ name: 'login' }" class="nav-link" aria-current="page">
							<i class="fas fa-user" style="margin-right: 6pxl"></i>&nbsp;Sign In Here
						</router-link>
					</li>

					<!-- <li class="nav-item dropdown"> -->
					<li class="nav-item dropdown d-none">
						<router-link :to="{ name: 'register' }" class="nav-link" aria-current="page">
							<i class="fas fa-user-plus" style="margin-right: 6pxl"></i>&nbsp;Register
						</router-link>
					</li>
				</ul>
			</nav>

			<div>

			</div>


			<div id="target-resize" class="pt-3" style="height: 100vh; overflow-y: auto;">
				<div class="card mx-4" style="">
					<div class="card-body">
						<div class="d-flex justify-content-between">
							<div class="col-2">
								<div class="form-check form-check-inline">
									<input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1"
										value="option1" v-on:change="OnChangeSelect(true)" checked>
									<label class="form-check-label" for="inlineRadio1">Knot</label>
								</div>
								<div class="form-check form-check-inline">
									<input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2"
										value="option2" v-on:change="OnChangeSelect(false)">
									<label class="form-check-label" for="inlineRadio2">Km/h</label>
								</div>
							</div>
							<div class="col-sm">
								<div class="form-check-inline"><span style="font-size: 9px;"><strong>1knots=
											1.85kph</strong></span></div>
							</div>
							<div v-if="buttonAudio">
								<div class="row">
									<div class="col-8" style="padding-right:-2px;">
										<span class="text-danger text-end"><strong>Alert Voice</strong></span>
									</div>
									<div class="col-2">
										<div v-if="playIt" class="col-sm text text-end">
											<a @click.prevent="playDanger()"><i
													class="fas fa-volume-up faa faa-flash text-danger"></i></a>
										</div>
										<div v-if="!playIt" class="col-sm text text-end">
											<a @click.prevent="stopDanger()"><i
													class="fas fa-volume-mute text-secondary"></i></a>
										</div>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>

				<div class="container-fluid my-0 py-0" style="padding: 25px">
					<!-- ROW 1 -->
					<div class="row">
						<section :class="classes" v-for="(item, index) in chartOptions">
							<div class="card">
								<div class="card-header" style="font-size: 20px;"> <strong>{{ alat[index].nama_alat
										}}</strong></div>
								<div :class="['card-body']" auto-resize>
									<div id="main"
										style="user-select: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); position: relative;">
										<VChart class="chart" :option="item"
											style="user-select: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); position: relative;"
											autoresize />
									</div>

								</div>

								<hr style="visibility: hidden;" />

								<!-- Icon -->
								<div :class="['card-body', alat[index].color]">
									<div class="d-flex flex-row align-items-center justify-content-center">
										<div class="flex-shrink-1 pe-4">
											<img src="../assets/compass_north.svg"
												:style="{ width: 32 + 'px', transform: 'rotate(' + alat[index].nilai_winddirection + 'deg)' }">
										</div>
										<div class="d-flex flex-column border-start border-start-1 border-light ps-2"
											style="font-size: 20px;">
											<div class="w-100 d-flex align-items-center mb-2">
												<span style="font-size: 14px">&nbsp;&nbsp;{{ alat[index].nama_en }}</span>
											</div>
											<div class="flex-shrink-1 d-flex align-items-center">
												<span style="font-size: 14px">&nbsp;&nbsp;{{ alat[index].nilai_winddirection
													}}<sup style="font-size: 14px">&deg;</sup></span>
											</div>
										</div>
									</div>
								</div>

								<!-- Audio -->
								<div class="card-footer">
									<div class="d-flex flex-row">
										<div class="flex-fill d-flex flex-row">
											<a @click.prevent="play(alat[index].keyid)"
												:class="['btn', alat[index].color, 'me-2']">
												<i :class="['fas', 'fa-bullhorn']"></i></a>
											<audio :id="['audio' + alat[index].keyid]" :src=alat[index].voice></audio>
											<audio :id="['audio2' + alat[index].keyid]" :src=alat[index].voice2></audio>
											<div style="font-size: 11px;">
												<i class="far fa-bell faa faa-swing text-danger"
													style="color:white"></i>&nbsp;<strong>{{ alat[index].kondisi }} </strong>
												<br>
												<i class="far fa-clock faa faa-swing text-danger"
													style="color:white"></i>&nbsp;<strong>{{ alat[index].lastupdate }} </strong>
											</div>
										</div>
									</div>
								</div>

								<!-- History Kalibrasi -->
								<div class="card-footer">
									<div class="d-flex flex-row">
										<!-- <div class="flex-fill d-flex flex-row"> -->
											<!-- <a @click.prevent="modal(alat[index].keyid)" class="btn bg-info me-2"><i
													:class="['fas', 'fa-info-circle']"></i></a>
											<div style="font-size: 11px;">
												<strong>History</strong>
												<br />
												<strong>today</strong>
											</div> -->
										<!-- </div> -->
										<div class="flex-fill d-flex flex-row">
											<a v-if="(alat[index].tgl_kalibrasi)"
												@click.prevent="modalkalibrasi(alat[index].doc_kalibrasi)"
												:class="['btn', alat[index].color, 'me-2']"><i
													:class="['fas', 'fa-file-pdf']"></i></a>
											<div style="font-size: 11px;">
												<strong>Dok.Kalibrasi </strong>
												<br />
												<strong>{{ alat[index].tgl_kalibrasi }}</strong>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
					</div>
				</div>
			</div>
			
		</div>

		<footer id="footer-bottom-target-min" class="main-footer" style="margin-left: -10px; padding: 20px; min-height: 5%;">
			<strong>Copyright © 2024
				<a target="_blank" href="http://www.jasamaritim.co.id">Pelindo Jasa Maritim</a>.</strong>
			All rights reserved.
			<div class="float-right d-none d-sm-inline-block">
				<b>Version</b> 1.0.0
			</div>
		</footer>
	</div>

		<!-- Modal Disini  -->
		<div class="modal" id="myModal">
			<div class="modal-dialog modal-xl">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">Wind Speed And Direction History</h5>
						<button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<DataTable :columns="columns" :data="tableGroupData" :options="dataTableOptions"
							ref="tableGroup" class="table table-bordered dataTableGroupClass" width="100%"></DataTable>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
					</div>
				</div>
			</div>
		</div>

		<!-- Modal Disini  -->
		<div class="modal" id="myModalKalibrasi">
			<div class="modal-dialog modal-xl">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">Dokumen Kalibrasi</h5>
						<button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<embed :src=pdfdoc width="100%" height="498px" />
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
					</div>
				</div>
			</div>
		</div>
</template>

<style>
@property --from {
	syntax: "<integer>";
	initial-value: 0;
	inherits: false;
}

a,
a label {
	cursor: pointer;
}

.counter {
	transition: --from 1s;
	counter-reset: int var(--from);
	animation: counter var(--time, 1000) forwards ease-in-out;
}

.counter::after {
	content: counter(int);
}

@keyframes counter {
	to {
		--from: var(--to, 100);
	}
}

.imgs {
	width: 34px;
	padding: 3px;
	margin: 3px;
	margin-right: 20px;
}

.chart {
	/* width: 400px;
  min-width: 100%; */
	width: 100%;
	height: 275px;
	margin-bottom: -10vh;
	margin-top: -4vh;
}

.content {
	background-image: url("../assets/background.png");
	background-repeat: repeat;
}

.custom-size {}
</style>